body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.logo-login {
  width: 100%;
  
}

.fs-24-rem {
  font-size: 3.5rem;
}

.btn-primary {
  background-color: #328BF1 !important;
}

.btn-primary:hover {
  background-color: #328BF1 !important;
}

.h-full {
  height: 100%;
}

.center-vertical {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
.hidden-mobile {
  display: none;
}
}

@media (min-width: 767px) {
  .only-mobile {
    display: none;
  }
  }

  .logo-login-2 {
    max-width: 200px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 40px;
  }

  .full-width {
    margin: 0 auto;
    width: 100%;
    display: flex;
  }